"use client";
import { useEffect } from "react";
import { ApolloError } from "@apollo/client";
import { isApolloError } from "@apollo/client/errors";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { shoppingSessionReactiveVar } from "@/hooks/session/useShoppingSession";
import {
  ANONYMOUS_UUID_COOKIE_NAME,
  DEVICE_ID_COOKIE_NAME,
  isServerParseError,
  RESTAURANT_ID_COOKIE_NAME,
  TOKEN_COOKIE_NAME,
} from "@/apollo/utils";
import { shoppingListsReactiveVar } from "@/hooks/lists/useShoppingLists";
import { InvalidTokenError } from "@/app/lib/utils";
import GlobalError from "@/components/GlobalError";
import { deleteCookie } from "cookies-next/client";

export default function Error({
  error,
  reset,
}: {
  error: ApolloError & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    if (
      isApolloError(error) &&
      isServerParseError(error?.networkError) &&
      (error.networkError.statusCode === 400 ||
        error.networkError.statusCode === 401)
    ) {
      shoppingSessionReactiveVar({
        data: null,
        loading: false,
      });
      shoppingListsReactiveVar(null);
      sessionUserReactiveVar({
        error: InvalidTokenError,
        loading: false,
      });

      reset();
    } else {
      deleteCookie(TOKEN_COOKIE_NAME);
      deleteCookie(RESTAURANT_ID_COOKIE_NAME);
      deleteCookie(ANONYMOUS_UUID_COOKIE_NAME);
      deleteCookie(DEVICE_ID_COOKIE_NAME);
      console.error(error.name, error.stack, error.message, error.digest);
    }
  }, [error, reset]);

  return (
    <GlobalError
      name={"Internal Server Error"}
      message={
        "Sorry we had some technical problems during your last operation."
      }
    />
  );
}
